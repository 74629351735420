<template>
  <div id="abseenteeismReports" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-1"
        >REPORTES DE AUSENTISMOS</v-row
      >
      <v-row no-gutters justify="start" class="mt-3 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="enterprise">Razón Social</label>
          <select
            name="enterprise"
            id="enterprise"
            v-model="enterprise"
            @change="obtenerReportes()"
          >
            <option selected disabled value="0">Selecciona Empresa</option>
            <option
              v-for="enterprise in enterprises"
              :key="enterprise.id"
              v-bind:value="enterprise.id"
            >
              {{ enterprise.razonSocial }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="area">Área</label>
          <select name="area" id="area" v-model="area" @change="cambioArea()">
            <option selected value="0">Sin especificar</option>
            <option v-for="area in areas" :key="area.id" v-bind:value="area.id">
              {{ area.nombreArea }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="sucursal">Centro de trabajo</label>
          <select
            name="sucursal"
            id="sucursal"
            v-model="sucursal"
            @change="cambioSucursal()"
          >
            <option selected value="0">Sin especificar</option>
            <option
              v-for="suc in sucursales"
              :key="suc.id"
              v-bind:value="suc.id"
            >
              {{ suc.nombreSucursal }}
            </option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters justify="start" class="mt-10 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="causa">Causa</label>
          <select
            name="causa"
            id="causa"
            v-model="causa"
            @change="
              solicitados = false;
              fechaFin = '';
              fechaInicio = '';
            "
          >
            <option selected disabled value="0">Seleccione</option>
            <option v-for="cau in causas" :key="cau.id" v-bind:value="cau.id">
              {{ cau.causa }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="fechaInicio">Fecha de Inicio</label>
          <input
            class="inputs"
            type="date"
            id="fechaInicio"
            name="fechaInicio"
            v-model="fechaInicio"
          />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="fechaFin">Fecha de Fin</label>
          <input
            class="inputs"
            type="date"
            id="fechaFin"
            name="fechaFin"
            v-model="fechaFin"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="solicitados"
        no-gutters
        class="datos pa-0 mt-1"
        style="height: auto !important"
      >
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="ausentismos"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Empleados por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.empleadoId"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.empleadoId }}</td>
                  <td class="text-sm-center">
                    {{
                      item.empleado.nombre +
                      " " +
                      item.empleado.apellidoPaterno +
                      " " +
                      item.empleado.apellidoMaterno
                    }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.empleado.puesto.nombrePuesto }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.empleado.area.nombreArea }}
                  </td>
                  <td class="text-sm-center">{{ item.empleado.nss }}</td>
                  <td class="text-sm-center">
                    {{ item.fechaAusentismoInicio.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.fechaReincorporacion.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.viaComunicacion.viaComunicacion }}
                  </td>
                  <td class="text-sm-center">{{ item.comentario }}</td>
                  <td class="text-sm-center" v-if="item.documentos.length < 3">
                    <v-col
                      v-if="item.documentos.length == 1"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-img
                        max-height="125"
                        max-width="125"
                        :src="
                          item.documentos[0].empleadoDocumentos.urlDocumento
                        "
                        @click="
                          abrirDocumento(
                            item.documentos[0].empleadoDocumentos.urlDocumento
                          )
                        "
                      ></v-img>
                    </v-col>
                    <v-row v-else>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        v-for="foto in item.documentos"
                        :key="foto.empleadoDocumentosId"
                      >
                        <v-img
                          max-height="125"
                          max-width="125"
                          :src="foto.empleadoDocumentos.urlDocumento"
                          @click="
                            abrirDocumento(foto.empleadoDocumentos.urlDocumento)
                          "
                        ></v-img> </v-col
                    ></v-row>
                  </td>
                  <td class="text-sm-center" v-else>
                    <span
                      v-for="foto in item.documentos"
                      :key="foto.empleadoDocumentosId"
                    >
                      <a :ref="foto.empleadoDocumentos.urlDocumento">{{
                        foto.empleadoDocumentos.urlDocumento
                      }}</a>
                    </span>
                  </td>
                  <td class="text-sm-center">{{ item.estatus.estatus }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <!--CUANDO ES NUEVA CONSULTA-->
      <v-row no-gutters justify="center" class="mt-3" v-if="comienzo == false">
        <v-col cols="12" lg="3" md="3" sm="3" xs="3">
          <button class="botonAmarillo" @click="validacion()">Consultar</button>
        </v-col>
      </v-row>
      <!--CUANDO YA SE HA REALIZADO ALGUNA CONSULTA-->
      <v-row no-gutters justify="center" class="mt-3" v-if="comienzo == true">
        <v-col cols="12" lg="3" md="3" sm="3" xs="3">
          <button class="botonAmarillo" @click="validacion()">
            Nueva consulta
          </button>
        </v-col>
      </v-row>
    </v-container>

    <!--Error-->
    <v-dialog v-model="alert" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <ul v-if="errors">
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="alert = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              aplica = 0;
              comentario = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Ausentismo</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="documento" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Documento presentado</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-img :src="url"></v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="documento = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      titulo: "",
      expiration: false,
      documento: false,
      edit: false,
      search: "",
      dialog: false,
      selectedItem: null,
      comentario: "",
      aplica: 0,
      person: localStorage.nombreEmpleadoGlobal,
      persona: localStorage.empleadoIdGlobal,
      fechaFin: "",
      fechaInicio: "",
      headers: [
        {
          text: "Id Empleado",
          align: "center",
          value: "empleadoId",
          sortable: false,
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "Puesto",
          align: "center",
          value: "puesto",
        },
        {
          text: "Área",
          align: "center",
          value: "area",
        },
        {
          text: "NSS",
          align: "center",
          value: "nss",
        },
        {
          text: "Fecha de Ausentismo",
          align: "center",
          value: "fechaAusentismoInicio",
        },
        {
          text: "Fecha de Reincoporación",
          align: "center",
          value: "fechaReincorporacion",
        },
        {
          text: "Vía Comunicación",
          align: "center",
          value: "viaComunicacion",
        },
        {
          text: "Comentario",
          align: "center",
          value: "comentario",
        },
        {
          text: "Documento",
          align: "center",
          value: "documento",
        },
        {
          text: "Estatus",
          align: "center",
          value: "estatus",
        },
      ],
      respuesta: "",
      reporte: 0,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      areas: [],
      area: 0,
      causas: [],
      causa: 0,
      sucursales: [],
      sucursal: 0,
      alert: false,
      errors: [],
      show: false,
      id: "",
      advice: false,
      confirmation: false,
      solicitados: false,
      comienzo: false,
      ausentismos: [],
      url: "",
      aplicaciones: [],
      reporteGenerado: [
        {
          idEmpleado: 1,
          nombre: "X Z L",
          puesto: "Gerente",
          area: "RH",
          fechaIngreso: "20-12-20",
          fechas: [
            { fecha: "15-01-19" },
            { fecha: "16-01-20" },
            { fecha: "17-01-20" },
          ],
          periodo: "verano",
          vigencia: "Ok",
        },
        {
          idEmpleado: 2,
          nombre: "X Z L",
          puesto: "Supervisor",
          area: "RH",
          fechaIngreso: "20-12-20",
          fechas: [
            { fecha: "19-01-19" },
            { fecha: "11-01-20" },
            { fecha: "10-01-20" },
          ],
          periodo: "verano",
          vigencia: "Ok",
        },
      ],
      error: false,
      calificacionItem: {},
      calificacion: false,
      empleadoCalificaId: 0,
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aviso() {
      this.edit = false;
      this.respuesta = "¿Esta seguro de guardar su calificación?";
      this.advice = true;
    },
    //Obtiene el catalogo de las aplicaciones por las cuales se reporta como ausente
    ausentismoAplicaciones() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-aplicaciones", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.aplicaciones = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/ausentismosLaborales/calificar-ausencia/" + this.id,
          {
            AplicacionId: parseInt(this.aplica),
            Comentario: this.comentario,
            EmpleadoCalificaId: parseInt(this.persona),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.aplica = 0;
          this.comentario = "";
          this.persona = "";
          this.respuesta = "La calificación fue otorgada con éxito.";
          this.peticion();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.aplica = 0;
            this.comentario = "";
            this.persona = "";
            this.respuesta =
              "La calificación no fue otorgada, vuelva a intentarlo.";
            this.listar();
            this.confirmation = true;
          }
        });
    },
    //Verifica regla de datos
    validacion() {
      this.errors = [];
      this.solicitados = false;
      if (this.enterprise == 0) {
        this.titulo = "Reporte de ausentismo";
        this.errors.push("Se debe de elegir la razón social.");
        this.alert = true;
      }
      if (this.enterprise != 0 && this.causa == 0) {
        this.titulo = "Error al generar el reporte";
        this.respuesta = "Es necesario corregir el siguiente error: ";
        this.errors.push(
          "Se debe elegir la causa de ausentimos para generar reporte"
        );
        this.alert = true;
        return;
      }
      if (
        this.causa != 0 &&
        this.area === 0 &&
        this.sucursal === 0 &&
        this.fechaInicio == "" &&
        this.fechaFin == ""
      ) {
        //sin area, sucursal y fechas
        this.peticion();
      } else {
        //con area,sucursal o fechas opcionales
        if (
          (this.fechaFin != "" && this.fechaInicio == "") ||
          (this.fechaInicio != "" && this.fechaFin == "")  
        ) {
          this.titulo = "Error al generar el reporte";
          this.respuesta = "Es necesario corregir el siguiente error: ";
          this.errors.push(
            "Favor de elegir fecha de inicio y fin del reporte"
          );
          this.alert = true;
          return;
        }
        if( this.fechaFin < this.fechaInicio){
          this.titulo = "Error al generar el reporte";
          this.respuesta = "Es necesario corregir el siguiente error: ";
          this.errors.push(
            "La fecha de inicio no puede ser superior a la fecha de fin del reporte."
          );
          this.alert = true;
          return;
        } else {
          this.peticion2();
        }
      }
    },
    abrirDocumento(url) {
      this.url = url;
      this.documento = true;
    },
    peticion2() {
      //this.alert = true;
      this.comienzo = true;
      this.show = true;
      axios
        .post(
          Server + "/ausentismosLaborales/reporte/" + this.causa,
          {
            EmpresaId: parseInt(this.enterprise),
            AreaId: parseInt(this.area),
            SucursalId: parseInt(this.sucursal),
            FechaInicio: this.fechaInicio,
            FechaFin: this.fechaFin
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.ausentismos = response.data;
          this.show = false;
          this.comienzo = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.comienzo = true;
          }
        });

      this.solicitados = true;
    },
    peticion() {
      //this.alert = true;
      this.comienzo = true;
      this.show = true;
      axios
        .get(
          Server +
            "/ausentismosLaborales/por-causa/" +
            this.enterprise +
            "/" +
            this.causa +
            "/",
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.ausentismos = response.data;
          this.show = false;
          this.comienzo = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.comienzo = true;
          }
        });
      this.solicitados = true;
    },
    //Obtencion de empresas
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.comienzo = true;
          }
        });
    },
    obtenerReportes() {
      this.causa = 0;
      this.area = 0;
      this.sucursal = 0;
      this.fechaFin = "";
      this.fechaInicio = "";
      this.comienzo = false;
      this.areas = [];
      this.show = true;
      //Obtiene todas las areas
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
                nombreCorto: value.nombreAreaCorto,
                empresa: value.empresaId,
                nombreEmpresa: value.empresa,
                nivelJerarquico: value.nivelJerarquico,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.sucursales = [];
      //Obtiene sucursales por empresa
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.sucursales = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //Reseteo de valores por cambio de area
    cambioArea() {
      this.sucursal = 0;
      this.causa = 0;
      this.fechaFin = "";
      this.fechaInicio = "";
      this.solicitados = false;
      this.enAnio = false;
      this.enMes = false;
      this.porDisfrutar = false;
    },
    //Reseteo de valores por cambio de sucursal
    cambioSucursal() {
      this.causa = 0;
      this.fechaFin = "";
      this.fechaInicio = "";
      this.solicitados = false;
      this.enAnio = false;
      this.enMes = false;
      this.porDisfrutar = false;
    },
    //Reseteo de valores por defecto
    limpiar() {
      this.area = 0;
      this.sucursal = 0;
      this.causas = 0;
      this.fechaFin = "";
      this.fechaInicio = "";
      this.areas = [];
      this.ausentismos = [];
      this.sucursales = [];
      this.solicitados = false;
      this.porDisfrutar = false;
      this.enAnio = false;
      this.enMes = false;
      //this.reporteGenerado=[];
      this.validacion();
    },
    //causas por las cuales puede ausentarse
    causasAusentismo() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-causas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.causas = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.ausentismoAplicaciones();
    this.causasAusentismo();
    this.obtenerReportes();
  },
};
</script>